@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html{
        @apply scroll-smooth;
    }
  
    [type='text'],
    [type='email'],
    [type='url'],
    [type='password'],
    [type='number'],
    [type='date'],
    [type='datetime-local'],
    [type='month'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='week'],
    [multiple],
    textarea {
      @apply w-full py-2 px-3 font-poppins font-normal border-gray-300 rounded-md shadow-none focus:ring-[0.5px] text-sm text-river-black
       focus:border-river-blue focus:ring-river-blue placeholder:!text-gray-300 placeholder:!font-poppins placeholder:!font-light;
    }
  
    select {
      @apply w-full py-2 px-3 border-gray-300 rounded-md shadow-none focus:ring-[0.5px] text-sm focus:border-river-blue 
      focus:ring-river-blue;
    }
  
    select option[disabled]:first-child {
      @apply hidden;
    }
  
    [type='checkbox'] {
      @apply w-[18px] h-[18px] border-[1.5px] border-gray-400 rounded-md text-[#27AE60] focus:ring-0
      focus:outline-none focus:shadow-none focus:shadow-transparent
      checked:bg-[url('./assets/icons/icon_input_checked.svg')]
      disabled:checked:bg-gray-300
      disabled:border-gray-300
      disabled:checked:bg-[url('./assets/icons/icon_input_checked.svg')];
    }
  
    [type='radio'] {
      @apply w-[18px] h-[18px] border-[1.5px] border-gray-400 rounded-full text-[#27AE60] focus:ring-0
      focus:outline-none focus:shadow-none focus:shadow-transparent
      checked:bg-[url('./assets/icons/icon_input_checked.svg')]
      disabled:bg-gray-300
      disabled:border-gray-300
      disabled:bg-[url('./assets/icons/icon_input_checked.svg')];
    }
  
    input[type='range'] {
      @apply appearance-none w-full;
    }
  
    input[type='range']::-webkit-slider-thumb {
      @apply appearance-none;
    }
  
    input[type='range']:hover::-webkit-slider-thumb {
      @apply shadow-[0_0_3px_5px_#cbebfc];
    }
  
    input[type='range']::-webkit-slider-thumb {
      @apply appearance-none h-[18px] w-[18px] bg-river-blue cursor-pointer;
    }
  
    input[type='range']::-webkit-slider-runnable-track {
      @apply appearance-none w-full bg-[#CBEBFC] h-[5px] rounded-lg cursor-pointer;
    }
}

@layer components {
    .pin-input .pincode-input-container {
        text-align: center;
    }
    
    .pin-input .pincode-input-container .pincode-input-text:focus {
        outline: 2px solid transparent !important;
        outline-offset: 2px !important;
        border-color: theme('borderColor.river-blue') !important;
        --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
          var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
          calc(0.5px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
        box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
          var(--tw-shadow, 0 0 #0000) !important;
        --tw-ring-opacity: 1 !important;
        --tw-ring-color: rgb(47 174 243 / var(--tw-ring-opacity)) !important;
        --tw-border-opacity: 1 !important;
    }
    
    .pin-input.error .pincode-input-container .pincode-input-text {
        border-color: theme('borderColor.river-red') !important;
    }
    
    .pin-input .pincode-input-container .pincode-input-text {
        width: 46px !important;
        height: 46px !important;
        padding: 8px 12px !important;
        border-width: 1px;
        border-color: theme('borderColor.gray.300') !important;
        border-radius: theme('borderRadius.md') !important;
        font-family: theme('fontFamily.poppins');
        font-style: normal;
        font-size: theme('fontSize.sm');
        color: theme('textColor.river-black');
        outline: 0;
        text-rendering: optimizeLegibility;
        margin: 0 10px !important;
    }

    .form-label {
        @apply font-medium text-lg text-gray-800;
    }

    .terms-max-height {
        height: 68vh;
    }

    .back-bg{
        background-color: #EEFBFF;
    }

    .note-bg {
        background-color: #FFEFD7;
    }

    .subText{
        color: #FF8C42;
    }

    .hintText {
        @apply text-xs font-normal leading-relaxed;
        color: #FF8C42;
    }

    .elementShadow{
        box-shadow: 0 2px 15px -1px rgba(0,0,0,.1), 0 6px 8px -4px rgba(0,0,0,.1);
    }

    .borderWidth {
        border-width: 1.5px !important;
    }

    .selectIcon {
        background-image: url("./assets/icons/icon_select_arrow_down.svg");
        background-repeat: no-repeat;
        background-position-x: 96.5%;
        background-position-y: 50%;
    }

    .m-center {
        margin-top: -104px;
    }

    .error-label{
        @apply font-normal text-river-deep-orange tablet:text-left text-center mt-2 w-full text-xs inline-block;
    }

    .appearance-none-webkit {
        -webkit-appearance: none;
    }

    label.checkbox-form-input {
      @apply w-full py-2 px-3 font-poppins font-normal border border-gray-300 rounded-md shadow-none focus:ring-[0.5px] text-sm text-river-black
       focus:border-river-blue focus:ring-river-blue placeholder:!text-gray-300 placeholder:!font-poppins placeholder:!font-light;
    }

    div.markdown a,
    p.markdown a {
      color: theme('textColor.river-blue') !important;
      font-weight: 500;
    }

    .alert {
      position: fixed;
      top: 0;
      left: 50%;
      pointer-events: none;
      -webkit-transform: translate(-50%,-100px);
      transform: translate(-50%,-100px);
      max-width: 640px;
      z-index: 999;
      text-align: center;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s linear .7s,opacity .7s,-webkit-transform .7s;
      transition: transform .7s,visibility 0s linear .7s,opacity .7s;
      transition: transform .7s,visibility 0s linear .7s,opacity .7s,-webkit-transform .7s;
    }

    .alert.show {
      transform: translate(-50%,0);
      visibility: visible;
      opacity: 1;
      transition-delay: 0s;
    }

    .alert-message {
      pointer-events: auto;
      background: rgba(255,255,255,.97);
      padding: 12px 25px;
      margin-bottom: 0;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.03em;
      box-shadow: 0 1px 1px rgb(0 0 0 / 25%), 0 0 1px rgb(0 0 0 / 35%);
      border-radius: 0 0 4px 4px;
      @apply shadow-xl text-river-black
    }

    .alert-action {
    
    }

    .alert--ERROR .alert-message {
      background: #FF6045;
      color: #fff;
    }

    .alert--INFO .alert-message {
      background: #FFFFFF;
      @apply text-river-black
    }

    .alert--INFO .alert-action {
      color: #43C42F   
    }

    .alert--WARNING .alert-message {
      color: #fff;
      @apply text-river-deep-orange
    }

    .alert--SUCCESS .alert-message {
      background: #72c16d;
      color: #fff;
    }

    @media (max-height: 600px) {
       .p-top{
           bottom: 60px !important;
       }
    }
}